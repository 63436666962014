$brand-primary: #a40b1e;
$brand-secondary: #808080;
$background-color: #ffffff;

$font-size-base: 0.8rem;

$brand-primary-dark: #83000F;
$brand-primary-darker: #5A000B;
$brand-primary-medium: #ca1c30;
$brand-primary-light: #f4e1e3;
$brand-primary-lighter: #f9eff0;

$brand-secondary-dark: #A8A8A8;
$brand-secondary-darker: #707070;
$brand-secondary-medium: #E0E0E0;
$brand-secondary-light: #F0F0F0;
$brand-secondary-lighter: #F8F8F8;

$brand-dark-grey: #575757;
$brand-light-grey: #B1B1B1;
$brand-lighter-grey: #C5C5C5;
$brand-white: #f7f9f9;


:root {
  //calculated colors from customcolors.scss or overwritten at runtime values
  --brand-primary: #a40b1e; //is overwritten at runtime
  --brand-primary-dark:  #83000F;
  --brand-primary-darker: #5A000B;
  --brand-primary-medium: #ca1c30;
  --brand-primary-light: #f4e1e3;
  --brand-primary-lighter: #f9eff0;

  --brand-secondary: #808080; //is overwritten at runtime
  --brand-secondary-dark: #A8A8A8;
  --brand-secondary-darker: #707070;
  --brand-secondary-medium: #E0E0E0;
  --brand-secondary-light: #F0F0F0;
  --brand-secondary-lighter: #F8F8F8;

  --background-color: #ffffff; //is overwritten at runtime
  --logo-height: 65px; //is overwritten at runtime

  --brand-dark-grey: #575757;
  --brand-light-grey: #B1B1B1;
  --brand-lighter-grey: #C5C5C5;
  --brand-white: #f7f9f9;

}

