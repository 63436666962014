@import 'customcolors';
@font-face {
  font-family: 'Liberation Sans';
  src: url('../assets/fonts/liberation_sans/LiberationSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('../assets/fonts/liberation_sans/LiberationSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('../assets/fonts/liberation_sans/LiberationSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('../assets/fonts/liberation_sans/LiberationSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

$brand-table-even: $brand-secondary-light;
$brand-table-odd: $brand-secondary-lighter;
$brand-table-highlight: $brand-primary-light;


:root {

  --brand-table-even: var(--brand-secondary-light, $brand-secondary-light);
  --brand-table-odd: var(--brand-secondary-lighter, $brand-secondary-lighter);
  --brand-table-highlight: var(--brand-primary-light, $brand-primary-light);

  body, pre {
    font-family: 'Liberation Sans', sans-serif !important;
  }

  pre {
    font-size: var(--front-size-base, $font-size-base);
  }

  .bg-primary{
    background-color: var(--brand-primary, $brand-primary);
  }
  .bg-secondary{
    background-color: var(--brand-secondary, $brand-secondary);
  }
  .bg-dark-grey{
    background-color: var(--brand-dark-grey, $brand-dark-grey);
  }

  .text-color-primary{
    color: var(--brand-primary, $brand-primary);
  }
}
